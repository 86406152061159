import React from "react";
import { string, bool, shape, arrayOf } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../Base/HeadingBuilder";
import CtaPrimary from "../../Base/CtaPrimary";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../hooks/use-start-motion";
import SectionHighlights from "../SectionHighlights";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const slideLeftVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const VerticalPageHighlights = ({
  className,
  title,
  imageName,
  ctaPrimary,
  highlights,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`
        relative max-w-1440 w-full
        px-4 lg:px-6
        ${className}
      `}
      ref={ref}
      data-cy="vertical-page-highlights"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-9">
        <motion.div
          className="order-2 lg:order-1"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideLeftVariants}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <AppetizeImage imageName={imageName} />
        </motion.div>

        <motion.div
          className="lg:-mt-36 order-1 lg:order-2"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideBottomVariants}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <Heading
            level={2}
            injectionType={1}
            className="xl:max-w-80-percent"
            withBlueSymbol
          >
            {title}
          </Heading>

          <CtaPrimary title={ctaPrimary.title} target={ctaPrimary.target} />
        </motion.div>
      </div>

      <SectionHighlights
        className="md:px-10 xl:px-20 2xl:px-40"
        highlights={highlights}
      />
    </section>
  );
};

VerticalPageHighlights.propTypes = {
  className: string,
  title: string.isRequired,
  imageName: string.isRequired,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  highlights: arrayOf(
    shape({
      title: string,
      subtext: string,
    })
  ).isRequired,
  animations: bool,
};

VerticalPageHighlights.defaultProps = {
  className: "",
  ctaPrimary: null,
  animations: true,
};

export default VerticalPageHighlights;
